@import url("https://use.typekit.net/pxu1ghy.css");
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap'); */

body {
  margin: 0px;
}

@font-face {
  font-family: 'Recoleta';
  src:
  local('Recoleta Medium'), local('Recoleta-Medium'),
      url('fonts/recoleta/Recoleta-Medium.eot?#iefix') format('embedded-opentype'),
      url('fonts/recoleta/Recoleta-Medium.woff2') format('woff2'),
      url('fonts/recoleta/Recoleta-Medium.woff') format('woff'),
      url('fonts/recoleta/Recoleta-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* @font-face {
  font-family: 'Recoleta';
  src: url('fonts/recoleta/Recoleta-Thin.eot';
  src: local('Recoleta Thin'), local('Recoleta-Thin'),
      url('fonts/recoleta/Recoleta-Thin.eot?#iefix'('embedded-opentype'),
      url('fonts/recoleta/Recoleta-Thin.woff2'('woff2'),
      url('fonts/recoleta/Recoleta-Thin.woff'('woff'),
      url('fonts/recoleta/Recoleta-Thin.ttf'('truetype');
  font-weight: 100;
  font-style: normal;
} */

@font-face {
  font-family: 'Recoleta';
  src:
  local('Recoleta SemiBold'), local('Recoleta-SemiBold'),
      url('fonts/recoleta/Recoleta-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('fonts/recoleta/Recoleta-SemiBold.woff2') format('woff2'),
      url('fonts/recoleta/Recoleta-SemiBold.woff') format('woff'),
      url('fonts/recoleta/Recoleta-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta';
  src:
  local('Recoleta Regular'), local('Recoleta-Regular'),
      url('fonts/recoleta/Recoleta-Regular.eot?#iefix') format('embedded-opentype'),
      url('fonts/recoleta/Recoleta-Regular.woff2') format('woff2'),
      url('fonts/recoleta/Recoleta-Regular.woff') format('woff'),
      url('fonts/recoleta/Recoleta-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta';
  src:
  local('Recoleta Bold'), local('Recoleta-Bold'),
      url('fonts/recoleta/Recoleta-Bold.eot?#iefix') format('embedded-opentype'),
      url('fonts/recoleta/Recoleta-Bold.woff2') format('woff2'),
      url('fonts/recoleta/Recoleta-Bold.woff') format('woff'),
      url('fonts/recoleta/Recoleta-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* @font-face {
  font-family: 'Recoleta';
  src:
  local('Recoleta Black'), local('Recoleta-Black'),
      url('fonts/recoleta/Recoleta-Black.eot?#iefix') format('embedded-opentype'),
      url('fonts/recoleta/Recoleta-Black.woff2') format('woff2'),
      url('fonts/recoleta/Recoleta-Black.woff') format('woff'),
      url('fonts/recoleta/Recoleta-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
} */

/* @font-face {
  font-family: 'Recoleta';
  src: url('fonts/recoleta/Recoleta-Light.eot';
  src: local('Recoleta Light'), local('Recoleta-Light'),
      url('fonts/recoleta/Recoleta-Light.eot?#iefix'('embedded-opentype'),
      url('fonts/recoleta/Recoleta-Light.woff2'('woff2'),
      url('fonts/recoleta/Recoleta-Light.woff'('woff'),
      url('fonts/recoleta/Recoleta-Light.ttf'('truetype');
  font-weight: 300;
  font-style: normal;
} */
