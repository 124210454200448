.root {
  transition: all .3s ease;
}

.root > .inner > .rightIcon {
  margin-left: 0;
}

.root > .inner > .rightIcon > .arrow {
  width: 6px;
  height: 6px;
  border-right: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  position: relative;
  transform: rotate(-45deg);
  margin: 0 6px;
  transition: all .3s ease;
}

.root > .inner > .rightIcon > .arrow::before {
  display: block;
  background-color: currentColor;
  width: 3px;
  transform-origin: bottom right;
  height: 2px;
  position: absolute;
  opacity: 0;
  bottom: calc(-2px / 2);
  transform: rotate(45deg);
  transition: all .3s ease;
  content: "";
  right: 0;
}

.root:hover > .inner > .rightIcon > .arrow {
  transform: rotate(-45deg) translate(4px, 4px);
  border-color: text-hover-color;
}

.root:hover > .inner > .rightIcon > .arrow::before {
  opacity: 1;
  width: 8px;
}